import { stripRichTextWrapperTag } from '../../utils/html';

export default ({ primary, items }, pageData, { $enhancedLinkSerializer }) => ({
  title: stripRichTextWrapperTag(primary.cta_list_title),
  links: items
    .filter(({ cta_link }) => cta_link?.data?.market?.uid)
    .map(({ cta_link, cta_link_label }) => ({
      label: cta_link_label,
      ...$enhancedLinkSerializer(cta_link),
    })),
});
